import $ from 'jquery';

let scrollPosi = 0;

function bodyFix() {
	scrollPosi = $(window).scrollTop();

	$('body').addClass('is-fixed').css({ top: -scrollPosi });
}

function bodyFixReset() {
	$('body').removeClass('is-fixed').css({ top: '0' });
	$('body, html').scrollTop(scrollPosi);
}

export { bodyFix, bodyFixReset };
