import $ from 'jquery';

const headerHeight = $('.c-header').outerHeight();


	$(window).on('scroll', function () {
		const footerOffset = $('.c-footer').offset().top;
		const header = $('.c-header');
		const scrolTop = $(window).scrollTop();

		if (scrolTop < footerOffset - headerHeight) {
			header.removeClass('is-hidden');
		} else {
			header.addClass('is-hidden');
		}
	});
