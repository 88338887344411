import $ from 'jquery';
import Header from '../Components/_Header.js';
import Footer from '../Components/_Footer.js';
import Anchor from '../Components/_Anchor.js';
import Swiper from '../Components/_Slider.js';
import Filter from '../Components/_Filters.js';
import MatchHeight from '../Components/_MatchHeights.js';

$(window).on('load resize', function () {
	if ($(window).outerWidth() < 768) {
		let sidebarB = 0;
		$('.js-lineheightSpan').each(function () {
			sidebarB = $(this).parents('aside').hasClass('c-sidebar') ? 31 : 0;
			let lineclamp =
				(($(this).parents('.js-hparent').height() - sidebarB) * 5) / 85;
			let lineclampMath = Math.round(lineclamp);
			//let bcd = ($(this).parents('.js-hparent').height() - sidebarB) * 17 / 85 + 'px';
			let lineheight =
				($(this).parents('.js-hparent').height() - sidebarB) / lineclampMath +
				'px';
			$(this).parents('.js-lineheight').css({
				'-webkit-line-clamp': lineclampMath.toString(),
				'line-height': lineheight,
			});
		});
	} else {
		$('.js-lineheight').removeAttr('style');
	}
});

// fadeUp
$(function () {
	let windowHeight = $(window).height();
	$(window).on('scroll load', function () {
		let scrollTop = $(this).scrollTop();
		$('.fadeUp').each(function () {
			let position = $(this).offset().top;
			if (scrollTop > position - windowHeight + 150) {
				$(this).addClass('is-show');
			}
		});
	});
});
