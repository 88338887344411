import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Isotope from 'isotope-layout';

$(function () {
	$(window).on('load', function () {

		jQueryBridget('isotope', Isotope, $);

		var $grid = $('#events_list').isotope({
			itemSelector: '.events_item'
		});

		$('#events_navi').on('click', '.js-filter_btn', function () {
			var $this = $(this);
			var $buttonGroup = $this.parent();
			var filterGroup = $buttonGroup.attr('data-filter-group');

			$grid.isotope({ filter: filterGroup });
		});

		$('.js-filter_group').on('click', function () {
			$('.js-filter_group').removeClass('is-checked');
			$(this).addClass('is-checked');
		});
	});
})
