import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const slider1 = new Swiper('.c-mvtop__slide-left', {
	loop: true,
	spaceBetween: 30,
	effect: 'fade',
	speed: 2000,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
});

const slider2 = new Swiper('.c-mvtop__slide-right', {
	loop: true,
	spaceBetween: 30,
	effect: 'fade',
	speed: 2000,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
});
