import $ from 'jquery';
import { bodyFix, bodyFixReset } from './_Functions.js';

const w = $(window);
const bodyHtml = $('body,html');
const html = $('html');
const body = $('body');

// hover display menu sub
$('.js-menusub')
	.on('mouseenter', function () {
		if (w.outerWidth() > 768) {
			$(this).find('.c-header__submenu1').addClass('is-show');
		}
	})
	.on('mouseleave', function () {
		if (w.outerWidth() > 768) {
			$(this).find('.c-header__submenu1').removeClass('is-show');
		}
	});

$('.js-iconmenu').on('click', function () {
	$(this).toggleClass('is-active');
	$('.c-header__nav').toggleClass('is-active');
	if ($('.js-iconmenu').hasClass('is-active')) {
		bodyFix();
	} else {
		bodyFixReset();
	}
});
